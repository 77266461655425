
$(document).on('ready turbolinks:load', function(){

    var windowWidth = window.innerWidth;
    
    if(windowWidth < 1024){
        $('.dropdown-menu-mobile ul').append($(".dropdown-menu-item:not('.last-menu-item')"));
        $(".header-menu-item").insertAfter($('.dropdown-menu-mobile'));
        $('.dropdown-menu-second-level, .dropdown-menu-third-level').closest('li').addClass("dropdown-menu-mobile");
    }

    $(".show-icon").click(function () {
        $(this).toggleClass("active");

        ($(this).hasClass("active")) ? 
            $(this).closest('.form-group').find('input').attr('type','text') : 
            $(this).closest('.form-group').find('input').attr('type','password');
    });

    $(".account-menu, .account-menu-mobile, .menu-mobile-close").on("click", function(){
        
        if($('.dropdown-menu-list').hasClass('open-menu-list')){
            $('.dropdown-menu-list').removeClass('open-menu-list');
            $(this).removeClass('open');
            $('.dropdown-menu-list').slideUp(300);
        }else{
            $('.dropdown-menu-list').addClass('open-menu-list');
            $(this).addClass('open');
            $('.dropdown-menu-list').slideDown(300);
        }
    });

    // For header main menu (dropdown toggle menu)
    $(document).on("click", ".header-menu a.dropdown-toggle", function(e){

        if($(this).next().is('.dropdown-menu-third-level')){
            e.stopImmediatePropagation();

            $(this).parent().siblings(".dropdown-menu-third-level").find("ul").slideUp(300);
            $(this).parent().siblings(".dropdown-menu-third-level").removeClass('open-menu');

            if($(this).parent().hasClass('open-menu')){
                $(this).parent().removeClass('open-menu');
                $(this).next('ul').slideUp(300);
            }else{
                $(this).parent().addClass('open-menu');
                $(this).next('ul').slideDown(300);
            }

            return false;
        }

    });

    $(document).on("click", ".dropdown-menu-mobile > a", function(e){

        e.stopImmediatePropagation();

        $(this).parent().siblings(".dropdown-menu-mobile").find("ul").slideUp(300);
        $(this).parent().siblings(".dropdown-menu-mobile").removeClass('open-mobile-menu');

        if($(this).parent().hasClass('open-mobile-menu')){
            $(this).parent().removeClass('open-mobile-menu');
            $(this).next('ul').slideUp(300);
        }else{
            $(this).parent().addClass('open-mobile-menu');
            $(this).next('ul').slideDown(300);
        }

        return false;
    });

    $('.search-header .clear-btn, .conversations-search-form .clear-btn').on("click", function(){
        $(this).parent().find('input').prop('value', '')
        return false;
    });

    $(document).click(function(event) { 
        $target = $(event.target);
        if(!$target.closest('.dropdown-menu-wrapper').length  && $('.dropdown-menu-list').is(":visible")) {
            $('.dropdown-menu-list').removeClass('open-menu-list');
            $('.account-menu').removeClass('open');
        }        
    });

    $(window).on('resize', function(){
        var windowWidth = window.innerWidth;
        if(windowWidth >= 1024){
          $(".dropdown-menu-item:not('.last-menu-item')").insertAfter($('.my-account-item').closest('.dropdown-menu-mobile'));
          $('.header-menu ').append($('.header-menu-item'));
          $('.dropdown-menu-second-level').closest('.header-menu-item').removeClass("dropdown-menu-mobile open-mobile-menu");
          $('.dropdown-menu-second-level, .dropdown-menu-third-level').hide();
        }else{
            
          $('.my-account-item').closest('.dropdown-menu-mobile').find('ul').append($(".dropdown-menu-item:not('.last-menu-item')"));
          $(".header-menu-item").insertAfter($('.my-account-item').closest('.dropdown-menu-mobile'));
         
          $('.dropdown-menu-second-level, .dropdown-menu-third-level').closest('li').addClass("dropdown-menu-mobile");
          $('.dropdown-menu-third-level').hide();
        }

        if(windowWidth <= 767){
          if($('.dropdown-menu-list').hasClass('open-menu-list') && !$('.dropdown-menu-mobile .search-input').is(":focus")){
            $('.dropdown-menu-mobile .search-input').trigger('focus');
          }
        }
    })

    /* 
        Ruby regular expression didn`t work on js
        app/models/user.rb
        //var regExp = /\A([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})\z/i; - from User.rb
    */

    // dropdown select filter
    $(document).ready(function(){

        var getUrlPathname = window.location.pathname.split('/');
        getUrlPathname = getUrlPathname[getUrlPathname.length - 1];

        // for sellsheet pages need to create other code

        if(getUrlPathname !== "tastingmat" && getUrlPathname !== "wifi" && getUrlPathname !== "edit_wifi" && getUrlPathname !== "group_training_activity"){

            let formGroupSelect = $('.form-group-select'),
            selectTitle = $('.select-title'),
            selectLabel = $('.select-label');
    
            $('.form-group-select').each(function(){
    
                let checkedElements = $(this).find('.select-input:checked').length,
                    selectTitle = $(this).find('.select-title'),
                    defaultValue = null,
                    labelText = $(this).find('input:checked').parent().find('.select-text').text(),
                    pageIsAssets = $('.assets-filters');
                
                if(checkedElements > 1){
    
                    if(pageIsAssets.length > 0) {
                        selectTitle.text(labelText);
                        $(this).find('.select-title-wrapper').addClass('no-before');
                    }else{
                        selectTitle.text(checkedElements + " " + "filters selected");
                    }
                    $(this).find('.clear-filter').show();
                }else if(checkedElements === 1){
    
                    if(pageIsAssets.length > 0) {
                        selectTitle.text(labelText);
                        $(this).find('.select-title-wrapper').addClass('no-before');
                    }else{
                        selectTitle.text(checkedElements + " " + "filters selected");
                    }
                    $(this).find('.clear-filter').show();
                } else{
                    defaultValue= $(this).find('.filbox-title-default').val();
                    selectTitle.text(defaultValue);
                    $(this).find('.clear-filter').hide();
                }
    
                if($(this).find('.select-input').length == 0){
                    $(this).addClass('disabled');
                }
    
            })
    
            // Toggle menu
            selectTitle.on('click', function() {
    
                let pageIsAssets = $('.assets-filters');
    
                //this if block open menu if user check one of the item, if page is assets
                if(pageIsAssets.length > 0) {
                    if($(this).closest(formGroupSelect).find('input[type=checkbox]:checked').length > 0){
                        return false;
                    }
                }
    
                if(!$(this).closest(formGroupSelect).hasClass('disabled')){
                    $(this).closest(formGroupSelect).siblings().removeClass('select-open');
                    if (!$(this).closest(formGroupSelect).hasClass('select-open')) {
                    $(this).closest(formGroupSelect).addClass('select-open');
                    } else {
                    $(this).closest(formGroupSelect).removeClass('select-open');
                    }
                }else{
                    return false
                }
            
            });
    
        
    
            // Close when click to option
            selectLabel.on('click', function() {
    
                // let checkedElements = $(this).closest(formGroupSelect).find('input[id ^= "brand_ids__"]:checked').length;
                // let selectTitle =$(this).closest(formGroupSelect).find('.select-title');
                // let defaultValue = null;
    
                // if(checkedElements > 1){
                //     $(this).closest(formGroupSelect).find(selectTitle).text(checkedElements + " " + "filters selected")
                // }else if(checkedElements === 1){
                //     $(this).closest(formGroupSelect).find(selectTitle).text(checkedElements + " " + "filter selected")
                // } else{
                //     defaultValue= $(this).closest(formGroupSelect).find('.filbox-title-default').val();
                //     $(this).closest(formGroupSelect).find(selectTitle).text(defaultValue);
                // }
                formGroupSelect.removeClass('select-open');
            });
    
            
            $(document).click(function(event) { 
            if(!$('.form-group-select').is(event.target) && $('.form-group-select').has(event.target).length === 0){
                formGroupSelect.removeClass('select-open');
            }
            });
        }
        
    });

    $('.btn-sort input[type="radio"]').on('change', function(){
        $('#wait-spinner').modal('show');
    });
    

    $(document).on('click', '.request-message-wrapper .close-button', function(){
        $(this).closest('.request-message-wrapper').finish().fadeOut(200);
    });


    // Special behavior for Conversation popup (close button and close popup by clicking on outside the popup area) 
    // BEGIN
    $(document).on('click', '.close-button', function(){
        if($(this).closest('.request-message-wrapper').hasClass('conversation-notification')){
            var form  = $(this).closest('.start-conversation-popup-wrapper').find('form');
            
            form.trigger('reset');
            form.find('select, input, textarea').prop('disabled', false);
            form.find('label').attr('disabled', false);
            $('.start-conversation-popup-wrapper').modal('hide');
        }
    });

    $(document).on('hidden.bs.modal', '.start-conversation-popup-wrapper', function () {
        $(this).find('.close-button').trigger('click');
    })
    // END

    $('.clear-filter').on('click', function(event){
        $('#wait-spinner').modal('show');
        $(this).closest('.form-group-select').find('input[type=checkbox]:checked').prop('checked', false);
        $(this).closest("form").submit();
    });

    $(".select-list").on("change", "input:checkbox, input:radio", function(){
        $('#wait-spinner').modal('show');
        $(this).closest("form").submit();
    });

    // Select all links with hashes and  Remove links that don't actually link to anything
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {

        // if conversation link
        if($(this).parent().hasClass('tab-item')) return false;
        
        // On-page links
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&  location.hostname == this.hostname) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - 20
            }, 1000, function() {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });
})